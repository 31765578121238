.TopContainer-NavBarContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.TopContainer-NavBarContainer #Text {
  flex: 1;
  margin-left: 18px;
  user-select: none;
}

.TopContainer-NavBarContainer #EndContent {
  align-self: center;
}
