@import url("https://fonts.googleapis.com/css2?family=Ramaraja&family=Roboto:wght@100&display=swap");

.LoginScreen-FullscreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubContainer-LoginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  max-width: 600px;
}

.LogoContainer-LoginScreen {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.InputContainer-LoginScreen {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
}

.ButtonContainer-LoginScreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
