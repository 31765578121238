:root {
  --padding-fix: 24px;
}

.FullscreenContainer {
  background-color: #15202b;
  padding-top: var(--padding-fix);
  padding-bottom: var(--padding-bottom-fix);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(
    var(--vh, 1vh) * 100 - var(--padding-fix, 0px) -
      var(--padding-bottom-fix, 0px)
  );
}
