@import url(https://fonts.googleapis.com/css2?family=Ramaraja&family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #15202b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


:root {
  --padding-fix: 24px;
}

.FullscreenContainer {
  background-color: #15202b;
  padding-top: 24px;
  padding-top: var(--padding-fix);
  padding-bottom: var(--padding-bottom-fix);
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(
    1vh * 100 - 24px -
      0px);
  height: calc(
    var(--vh, 1vh) * 100 - var(--padding-fix, 0px) -
      var(--padding-bottom-fix, 0px)
  );
}

.TopContainer-NavBarContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.TopContainer-NavBarContainer #Text {
  flex: 1 1;
  margin-left: 18px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.TopContainer-NavBarContainer #EndContent {
  align-self: center;
}






.SelectContainer {
  width: 100%;
  border-radius: 32px;
}



.UsersScreen-FullscreenContainer {
  padding-bottom: var(--padding-fix);
}

.UsersScreen-Table {
  flex: 1 1;
}

.PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
}


.LoginScreen-FullscreenContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubContainer-LoginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  max-width: 600px;
}

.LogoContainer-LoginScreen {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.InputContainer-LoginScreen {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
}

.ButtonContainer-LoginScreen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Divider {
  border-style: solid none none none;
  border-width: 0.1px;
  border-color: white;
  margin-top: 12px;
  padding-bottom: 12px;
}

.Module-Header {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ProfileScreen-Content {
  padding-top: 16px;
}

body {
  background-color: #15202b;
}

