.UsersScreen-FullscreenContainer {
  padding-bottom: var(--padding-fix);
}

.UsersScreen-Table {
  flex: 1;
}

.PaddingContainer {
  padding-left: 24px;
  padding-right: 24px;
}
